<template>
  <v-dialog max-width="500" v-model="dialog.display">
    <v-card>
      <v-card-title>
        <div class="text-subtitle-1">
          <span class="secondary-font font-weight-bold">
            {{ $t("user.display.title") }}
          </span>
        </div>
        <v-btn class="ml-auto" icon @click="dialog.display = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid grid-list-md v-if="!_.isEmpty(user)">
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1">mdi-calendar-blank</v-icon>
                <span class="text-body-1 font-weight-bold">
                  {{ $t("user.fields.created.title") }}
                </span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip color="fourth" class="table-chips mr-0">
                  <span class="text-body-2">
                    <span class="font-weight-bold">
                      {{ $moment(user.created).format("YYYY-MM-DD HH:mm:ss") }}
                    </span>
                  </span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <template v-if="loading.bulksms">
            <v-row align="center" justify="center" class="pt-2">
              <v-col cols="12" class="pb-0">
                <div class="text-center">
                  <v-progress-circular
                    indeterminate
                    color="secondary"
                    width="2"
                    size="20"
                  ></v-progress-circular>
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <template v-if="application.bulksms.balance">
              <v-row align="center" justify="center" class="py-2">
                <v-col cols="12" sm="8">
                  <div>
                    <v-icon class="mr-2 pb-1">mdi-wallet-outline</v-icon>
                    <span class="text-body-1 font-weight-bold">
                      {{ $t("bulksms.balance") }}
                    </span>
                  </div>
                </v-col>
                <v-col cols="12" sm="4">
                  <div class="text-right">
                    <v-chip color="fourth" class="table-chips mr-0">
                      <span class="text-body-2">
                        <span class="font-weight-bold">
                          <span
                            v-if="application.bulksms.accountType === 'PrePaid'"
                            >{{ application.bulksms.balance }} SMS</span
                          >
                          <span v-else>POSTPAID</span>
                        </span>
                      </span>
                    </v-chip>
                  </div>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </template>
          </template>
          <template v-if="user.lastLogin">
            <v-row align="center" justify="center" class="py-2">
              <v-col cols="12" sm="8">
                <div>
                  <v-icon class="mr-2 pb-1">mdi-calendar-blank</v-icon>
                  <span class="text-body-1 font-weight-bold">
                    {{ $t("user.fields.last_login.title") }}
                  </span>
                </div>
              </v-col>
              <v-col cols="12" sm="4">
                <div class="text-right">
                  <v-chip color="fourth" class="table-chips mr-0">
                    <span class="text-body-2">
                      <span class="font-weight-bold">
                        {{
                          $moment(user.lastLogin).format("YYYY-MM-DD HH:mm:ss")
                        }}
                      </span>
                    </span>
                  </v-chip>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </template>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1">mdi-account-check-outline</v-icon>
                <span class="text-body-1 font-weight-bold">
                  {{ $t("user.fields.email_confirm.title") }}
                </span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip
                  color="success"
                  dark
                  class="table-chips"
                  v-if="user.active"
                  >{{ $t("btn.yes") }}</v-chip
                >
                <v-chip color="danger" dark class="table-chips" v-else>{{
                  $t("btn.no")
                }}</v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <template v-if="user.role === 'ROLE_CLIENT'">
            <v-row align="center" justify="center" class="py-2">
              <v-col cols="12" sm="4">
                <div>
                  <v-icon class="mr-2 pb-1">mdi-shield-key-outline</v-icon>
                  <span class="text-body-1 font-weight-bold">
                    {{ $t("security.fields.api_key.title") }}
                  </span>
                </div>
              </v-col>
              <v-col cols="12" sm="8">
                <div class="text-right">
                  <v-chip color="fourth" class="table-chips mr-0">
                    <span class="text-body-2">
                      <span class="font-weight-bold">
                        {{ user.apiKey }}
                      </span>
                    </span>
                  </v-chip>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row align="center" justify="center" class="py-2">
              <v-col cols="12" sm="4">
                <div>
                  <v-icon class="mr-2 pb-1">mdi-shield-key-outline</v-icon>
                  <span class="text-body-1 font-weight-bold">
                    {{ $t("security.fields.secret.title") }}
                  </span>
                </div>
              </v-col>
              <v-col cols="12" sm="8">
                <div class="text-right">
                  <v-chip color="fourth" class="table-chips mr-0">
                    <span class="text-body-2">
                      <span class="font-weight-bold">
                        {{ user.secret }}
                      </span>
                    </span>
                  </v-chip>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </template>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1">mdi-identifier</v-icon>
                <span class="text-body-1 font-weight-bold">
                  {{ $t("user.fields.username.title") }}
                </span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip color="fourth" class="table-chips mr-0">
                  <span class="text-body-2">
                    <span class="font-weight-bold">
                      {{ user.username }}
                    </span>
                  </span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="4">
              <div>
                <v-icon class="mr-2 pb-1">mdi-at</v-icon>
                <span class="text-body-1 font-weight-bold">
                  {{ $t("user.fields.email.title") }}
                </span>
              </div>
            </v-col>
            <v-col cols="12" sm="8">
              <div class="text-right">
                <v-chip color="fourth" class="table-chips mr-0">
                  <span class="text-body-2">
                    <span class="font-weight-bold">
                      {{ user.email }}
                    </span>
                  </span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="4">
              <div>
                <v-icon class="mr-2 pb-1"
                  >mdi-card-account-details-outline</v-icon
                >
                <span class="text-body-1 font-weight-bold">
                  {{ $t("user.fields.first_name.title") }}
                </span>
              </div>
            </v-col>
            <v-col cols="12" sm="8">
              <div class="text-right">
                <v-chip color="fourth" class="table-chips mr-0">
                  <span class="text-body-2">
                    <span class="font-weight-bold">
                      {{ user.profile.firstName }}
                    </span>
                  </span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1"
                  >mdi-card-account-details-outline</v-icon
                >
                <span class="text-body-1 font-weight-bold">
                  {{ $t("user.fields.last_name.title") }}
                </span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip color="fourth" class="table-chips mr-0">
                  <span class="text-body-2">
                    <span class="font-weight-bold">
                      {{ user.profile.lastName }}
                    </span>
                  </span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="7">
              <div>
                <v-icon class="mr-2 pb-1">mdi-phone-outline</v-icon>
                <span class="text-body-1 font-weight-bold">
                  {{ $t("user.fields.phone.title") }}
                </span>
              </div>
            </v-col>
            <v-col cols="12" sm="5">
              <div class="text-right">
                <v-chip color="fourth" class="table-chips mr-0">
                  <span class="text-body-2">
                    <span class="font-weight-bold">
                      {{ user.profile.phone }}
                    </span>
                  </span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1">mdi-account-group-outline</v-icon>
                <span class="text-body-1 font-weight-bold">
                  {{ $t("user.fields.role.title") }}
                </span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip color="fourth" class="table-chips mr-0">
                  <span class="text-body-2">
                    <span class="font-weight-bold">
                      {{ $t(`user.role.${user.role}`) }}
                    </span>
                  </span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1"
                  >mdi-checkbox-blank-circle-outline</v-icon
                >
                <span class="text-body-1 font-weight-bold">
                  {{ $t("user.fields.status.title") }}
                </span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip
                  color="success"
                  dark
                  class="table-chips"
                  v-if="user.status"
                  >{{ $t("user.fields.status.enabled") }}</v-chip
                >
                <v-chip color="danger" dark class="table-chips" v-else>{{
                  $t("user.fields.status.disabled")
                }}</v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <template v-if="$objectPath.has(extras, 'cameroon_region')">
            <v-row align="center" justify="center" class="py-2">
              <v-col cols="12" sm="8">
                <div>
                  <v-icon class="mr-2 pb-1">mdi-map-marker-outline</v-icon>
                  <span class="text-body-1 font-weight-bold">
                    {{ $t("user.fields.region.title") }}
                  </span>
                </div>
              </v-col>
              <v-col cols="12" sm="4">
                <div class="text-right">
                  <v-chip color="fourth" class="table-chips mr-0">
                    <span class="text-body-2">
                      <span class="font-weight-bold">
                        {{
                          $objectPath.get(
                            regions,
                            `${$i18n.locale}.${extras.cameroon_region}`,
                            extras.cameroon_region
                          )
                        }}
                      </span>
                    </span>
                  </v-chip>
                </div>
              </v-col>
            </v-row>
          </template>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    dialog: {
      display: {
        type: Object,
        default: function() {
          return {};
        },
      },
    },
    user: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  data: () => ({
    regions: {},
    application: {
      bulksms: {},
    },
    loading: {
      bulksms: false,
    },
  }),
  methods: {
    ...mapActions({ request: "request" }),
  },
  watch: {
    async "dialog.display"(val) {
      if (!val) return;

      this.application.bulksms = {};

      if (this.user.role !== "ROLE_CLIENT") return;

      try {
        this.loading.bulksms = true;
        const response = await this.request({
          url: `/api/providers/bulksms/${this.user.id}/infos`,
        });
        this.application.bulksms = response.data;
      } catch (error) {
        // empty
      }
      this.loading.bulksms = false;
    },
  },
  computed: {
    extras() {
      const extras = {};
      if (this.user.extras) {
        for (const extra of this.user.extras) {
          extras[extra.item] = extra.value;
        }
      }
      return extras;
    },
  },
};
</script>
