<template>
  <div>
    <div class="text-h6 font-weight-medium mb-4">{{ $t("user.title") }}</div>
    <v-card class="rounded-md mb-4">
      <div
        class="d-flex align-center justify-space-between px-4 py-3 flex-wrap"
      >
        <div>
          <v-btn
            depressed
            @click="refreshItems()"
            :loading="loading.refresh"
            class="mr-2 mb-2"
          >
            <v-icon left>mdi-refresh</v-icon>
            {{ $t("btn.refresh") }}
          </v-btn>
          <v-btn
            class="mb-2 mr-2"
            depressed
            @click="filterItems()"
            :loading="loading.filter"
          >
            <v-icon left>mdi-filter-variant</v-icon>
            {{ $t("btn.filter") }}
          </v-btn>
          <v-btn
            @click="exportUsers()"
            :loading="loading.export"
            :disabled="loading.list"
            class="mb-2 mr-2"
            depressed
          >
            <v-icon small left>mdi-file-excel-box-outline</v-icon>
            {{ $t("btn.export") }}
          </v-btn>
        </div>
        <div v-if="$auth.check(['ROLE_ADMIN'])">
          <v-btn @click="addUser()" class="mb-2" color="primary">
            <v-icon left>mdi-account-plus-outline</v-icon>
            {{ $t("btn.add") }}
          </v-btn>
        </div>
      </div>
      <v-data-table
        :loading="loading.list"
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :footer-props="footerProps"
        :items-per-page="itemPerPage"
        sort-by="id"
        sort-desc
        :server-items-length="meta.total"
      >
        <template v-slot:body="{ items }" v-if="!_.isEmpty(items)">
          <tbody>
            <template v-for="(item, index) in items">
              <v-hover :key="item.id" v-slot="{ hover }">
                <tr>
                  <td>
                    <span>{{
                      $moment(item.created).format("YYYY-MM-DD HH:mm:ss")
                    }}</span>
                  </td>
                  <td>
                    <v-chip
                      color="success"
                      dark
                      class="table-chips"
                      v-if="item.status"
                      >{{ $t("user.fields.status.enabled") }}</v-chip
                    >
                    <v-chip color="danger" dark class="table-chips" v-else>{{
                      $t("user.fields.status.disabled")
                    }}</v-chip>
                  </td>
                  <td>
                    <v-chip color="fourth" class="table-chips">{{
                      $t(`user.role.${item.role}`)
                    }}</v-chip>
                  </td>
                  <td>{{ item.username }}</td>
                  <td>{{ item.email }}</td>
                  <td style="min-width: 150px;" class="text-right">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="displayUser(item)"
                          v-show="hover || $vuetify.breakpoint.mdAndDown"
                          x-small
                          icon
                          v-bind="attrs"
                          v-on="on"
                          class="mr-2"
                        >
                          <v-icon color="primary">mdi-eye-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("btn.display.description") }}</span>
                    </v-tooltip>
                    <template v-if="$auth.check(['ROLE_ADMIN'])">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="editUser(item)"
                            v-show="hover || $vuetify.breakpoint.mdAndDown"
                            x-small
                            icon
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2"
                          >
                            <v-icon color="primary">mdi-pencil-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("btn.edit") }}</span>
                      </v-tooltip>
                    </template>
                    <template
                      v-if="
                        item.role !== 'ROLE_ADMIN' &&
                          $auth.check(['ROLE_ADMIN'])
                      "
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="changeStatus(item, index)"
                            v-show="hover || $vuetify.breakpoint.mdAndDown"
                            x-small
                            icon
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2"
                          >
                            <v-icon color="primary">{{
                              item.status
                                ? "mdi-checkbox-blank-circle-outline"
                                : "mdi-checkbox-marked-circle-outline"
                            }}</v-icon>
                          </v-btn>
                        </template>
                        <span v-if="item.status">{{
                          $t("user.btn.status.disable")
                        }}</span>
                        <span v-else>{{ $t("user.btn.status.enable") }}</span>
                      </v-tooltip>
                    </template>

                    <template
                      v-if="
                        (($auth.check(['ROLE_COMMERCIAL']) &&
                          item.role === 'ROLE_CLIENT') ||
                          $auth.check(['ROLE_ADMIN'])) &&
                          item.status
                      "
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="impersonateUser(item)"
                            v-show="hover || $vuetify.breakpoint.mdAndDown"
                            x-small
                            icon
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2"
                          >
                            <v-icon color="primary"
                              >mdi-account-arrow-left-outline</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>{{ $t("user.btn.impersonate") }}</span>
                      </v-tooltip>
                    </template>
                  </td>
                </tr>
              </v-hover>
            </template>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
    <user-view :dialog="dialog.view" :user="user"></user-view>
    <user-filter :dialog="dialog.filter" @filter="applyFilter"></user-filter>
    <confirm :dialog="dialog.status" @confirm="confirmChangeStatus"></confirm>
    <user-form
      :user="user"
      :dialog="dialog.form"
      @saved="refreshItems"
    ></user-form>
  </div>
</template>

<script>
import Confirm from "./../commons/confirm";
import UserFilter from "./filter";
import UserView from "./view";
import ListMixin from "./../../mixins/commons/list";
import UserForm from "./form.vue";
import { mapActions } from "vuex";

export default {
  mixins: [ListMixin],
  async created() {
    // init cameroon regions
    await this.setRegions();
  },
  data: () => ({
    path: "/api/users",
    user: {},
    userIndex: null,
    dialog: {
      view: {
        display: false,
      },
      filter: {
        display: false,
      },
      status: {
        display: false,
        message: null,
      },
      form: {
        display: false,
        title: null,
      },
    },
  }),
  methods: {
    setHeaders() {
      this.headers = [
        {
          text: this.$t("user.fields.created.title"),
          align: "start",
          sortable: true,
          value: "created",
          width: 160,
        },
        {
          text: this.$t("user.fields.status.title"),
          align: "start",
          sortable: true,
          value: "status",
          width: 120,
        },
        {
          text: this.$t("user.fields.role.title"),
          align: "start",
          sortable: true,
          value: "role",
          width: 120,
        },
        {
          text: this.$t("user.fields.username.title"),
          align: "start",
          sortable: true,
          value: "username",
          width: 120,
        },
        {
          text: this.$t("user.fields.email.title"),
          align: "start",
          sortable: true,
          value: "email",
        },
        {
          text: "",
          align: "start",
          sortable: true,
          value: null,
          width: 150,
        },
      ];
    },
    displayUser(user) {
      this.user = user;
      this.dialog.view.display = true;
    },
    filterItems() {
      this.dialog.filter.display = true;
    },
    changeStatus(item, index) {
      this.userIndex = index;
      this.user = item;
      this.dialog.status.display = true;
      this.dialog.status.message = !item.status
        ? this.$t("user.changeStatus.confirm.enabled")
        : this.$t("user.changeStatus.confirm.disabled");
    },
    async confirmChangeStatus() {
      const url = `/api/users/${this.user.id}/${
        this.user.status ? "disable" : "enable"
      }`;
      try {
        await this.request({
          url: url,
          method: "post",
          messages: { 403: true, 404: this.$t("user.errors.not_found") },
        });
        this.user.status = !this.user.status;
        this.items[this.userIndex] = this.user.status;
      } catch (error) {
        // empty
      }
    },
    async impersonateUser(item) {
      try {
        const tokens = this.$auth.token();
        let tokensArray = tokens.split("||");
        await this.$auth.impersonate({
          data: { userId: item.id, refresh_token: tokensArray[1] },
          messages: { 403: true, 404: this.$t("user.errors.not_found") },
        });
      } catch (error) {
        // empty
      }
    },
    addUser() {
      this.user = {};
      this.dialog.form.title = this.$t("user.add.title");
      this.dialog.form.display = true;
    },
    editUser(data) {
      this.user = data;
      this.dialog.form.title = this.$t("user.edit.title", {
        username: data.username,
      });
      this.dialog.form.display = true;
    },
    async exportUsers() {
      try {
        const response = await this.exportItems("/api/users/export");
        if (!response) return;
        const url =
          (process.env.VUE_APP_BASE_API || "") + "/" + response.data.filename;
        this.$utils.downloadUri(url);
      } catch (error) {
        // empty
        console.log(error);
      }
    },
    ...mapActions({
      setRegions: "setRegions",
    }),
  },
  components: {
    UserView,
    UserFilter,
    Confirm,
    UserForm,
  },
};
</script>
