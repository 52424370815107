<template>
  <v-dialog v-model="dialog.display" persistent max-width="500">
    <v-card>
      <v-card-title>
        <div class="text-subtitle-1">
          <span class="secondary-font font-weight-bold">
            {{ dialog.title }}
          </span>
        </div>
        <v-btn class="ml-auto" icon @click="dialog.display = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-7 pt-7">
        <v-select
          :items="rolesOptions"
          name="role"
          dense
          class="mb-4 font-weight-bold"
          :label="$t('user.fields.role.title2')"
          v-model="form.role"
          @input="delayTouch($v.form.role)"
          @blur="$v.form.role.$touch"
          append-icon="mdi-account-group-outline"
          :item-text="(item) => $t(item.text)"
          :error-messages="roleErrors"
        ></v-select>
        <v-text-field
          append-icon="mdi-at"
          name="email"
          v-model="form.email"
          :label="`${$i18n.t('security.fields.email.title')} *`"
          type="email"
          autocomplete="off"
          dense
          class="mb-4 font-weight-bold"
          @input="delayTouch($v.form.email)"
          @blur="$v.form.email.$touch"
          :error-messages="emailErrors"
          maxlength="100"
        ></v-text-field>
        <v-text-field
          name="phone"
          v-model="form.phone"
          :label="`${$i18n.t('security.fields.phone.title')} *`"
          type="phone"
          autocomplete="off"
          dense
          class="mb-4 font-weight-bold"
          @input="delayTouch($v.form.phone)"
          @blur="$v.form.phone.$touch"
          :error-messages="phoneErrors"
          maxlength="20"
        >
          <template v-slot:append>
            <div style="width: 32px">
              <span
                class="flag-icon"
                v-if="countryCode"
                :class="`flag-icon-${countryCode.toLowerCase()}`"
                style="font-size: 1.9rem"
              ></span>
              <span v-else><v-icon>mdi-phone-outline</v-icon></span>
            </div>
          </template>
        </v-text-field>
        <v-text-field
          append-icon="mdi-account-circle-outline"
          name="firstName"
          v-model="form.firstName"
          :label="`${$i18n.t('security.fields.first_name.title')} *`"
          autocomplete="off"
          dense
          class="mb-4 font-weight-bold"
          @input="delayTouch($v.form.firstName)"
          @blur="$v.form.firstName.$touch"
          :error-messages="firstNameErrors"
          maxlength="100"
        ></v-text-field>
        <v-text-field
          append-icon="mdi-account-circle-outline"
          name="lastName"
          v-model="form.lastName"
          :label="`${$i18n.t('security.fields.last_name.title')} *`"
          autocomplete="off"
          dense
          class="mb-4 font-weight-bold"
          @input="delayTouch($v.form.lastName)"
          @blur="$v.form.lastName.$touch"
          :error-messages="lastNameErrors"
          maxlength="100"
        ></v-text-field>
        <v-text-field
          :append-icon="!form.password ? 'mdi-lock-outline' : 'mdi-eye-outline'"
          name="password"
          v-model="form.password"
          :label="$t('security.fields.password.title')"
          :type="passwordType"
          autocomplete="off"
          dense
          class="mb-4 font-weight-bold"
          maxlength="30"
          @input="delayTouch($v.form.password)"
          @blur="$v.form.password.$touch"
          :error-messages="passwordErrors"
          @click:append="changePasswordType()"
        ></v-text-field>
        <v-text-field
          append-icon="mdi-lock-outline"
          name="confirmPassword"
          v-model="form.confirmPassword"
          :label="$t('security.fields.confirm_password.title')"
          :type="passwordType"
          autocomplete="off"
          dense
          class="mb-4 font-weight-bold"
          maxlength="30"
          @input="delayTouch($v.form.confirmPassword)"
          @blur="$v.form.confirmPassword.$touch"
          :error-messages="confirmPasswordErrors"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog.display = false">{{
          $t("btn.close")
        }}</v-btn>
        <v-btn
          :loading="loading"
          text
          color="primary"
          class="font-weight-bold"
          @click="save()"
          :disabled="$v.form.$invalid"
        >
          <span v-if="!isUpdate">{{ $t("btn.save") }}</span>
          <span v-else>{{ $t("btn.update") }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import FormDelayTouchMixin from "./../../mixins/form-delay-touch";
import store from "./../../store";
import { mapGetters, mapActions } from "vuex";

import {
  email,
  required,
  minLength,
  maxLength,
  helpers,
  sameAs,
} from "vuelidate/lib/validators";

const defaultForm = {
  firstName: null,
  lastName: null,
  phone: null,
  role: null,
  email: null,
  password: null,
  confirmPassword: null,
};
export default {
  mixins: [FormDelayTouchMixin],
  props: {
    user: Object,
    dialog: {
      type: Object,
      default: function() {
        return {
          display: false,
          title: null,
        };
      },
    },
  },
  data: () => ({
    form: { ...defaultForm },
    phoneIsValid: false,
    countryCode: null,
    passwordType: "password",
    loading: false,
  }),
  validations() {
    const validators = {
      form: {
        role: {
          required,
          matchRole: (value) =>
            !helpers.req(value) ||
            helpers.regex(
              "matchRole",
              /^(ROLE_ADMIN|ROLE_COMMERCIAL|ROLE_COMPTA|ROLE_CLIENT)$/
            ),
        },
        email: {
          required,
          email,
          maxLength: maxLength(100),
          isUnique: async (value) => {
            if (!helpers.req(value)) return true;
            
            if(this.user?.email === value) return true;
            try {
              await store.dispatch("request", {
                method: "post",
                url: "/api/auth/email-exists",
                data: { email: value },
              });
              return false;
            } catch (error) {
              if (error.response) {
                if (error.response.status === 404) {
                  return true;
                }
              }
              return false;
            }
          },
        },
        phone: {
          required,
          matchPhone: (value) => !helpers.req(value) || this.phoneIsValid,
        },
        firstName: {
          required,
          maxLength: maxLength(100),
        },
        lastName: {
          required,
          maxLength: maxLength(100),
        },
        confirmPassword: {
          sameAsPassword: sameAs("password"),
        },
      },
    };

    if (!this.isUpdate) {
      validators.form.password = {
        required,
        minLength: minLength(8),
        maxLength: maxLength(30),
        matchPassword: helpers.regex("pass", Vue.$utils.regexPassword),
      };
    } else {
      validators.form.password = {
        minLength: minLength(8),
        maxLength: maxLength(30),
        matchPassword: helpers.regex("pass", Vue.$utils.regexPassword),
      };
    }

    return validators;
  },
  methods: {
    initForm() {
      if(this.isUpdate) {
        this.form = {...defaultForm};
        this.form.firstName = this.user.profile.firstName;
        this.form.lastName = this.user.profile.lastName;
        this.form.role = this.user.role;
        this.form.email = this.user.email;
        this.form.phone = (this.user.profile.phone || '').replace('+', '');
        this.$v.form.$reset();
      }else{
        this.form = {...defaultForm};
      }
    },
    changePasswordType() {
      if (!this.form.password) return;
      if (this.passwordType === "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
    async save() {
      this.notify({ status: false });

      // check if form is valid
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.notify({
          message: this.$t("error_codes.bad_request"),
          right: false,
          centered: true,
        });
        return;
      }

      try {
        let url = "/api/users";
        let method = "post";

        if (this.isUpdate) {
          method = "put";
          url = `/api/users/${this.user.id}`;
        }

        await this.request({
          url: url,
          method: method,
          data: {
            email: this.form.email,
            password: this.form.password,
            firstName: this.form.firstName,
            lastName: this.form.lastName,
            phone: this.form.phone,
            role: this.form.role,
          },
          messages: {
            500: true,
            400: this.$t("error_codes.bad_request"),
            422: (error) => {
              let message = "error_codes.error_occured";
              switch (error.code) {
                case "E101":
                  message = "security.fields.phone.invalid";
                  break;
                case "E100":
                  message = "security.fields.email.exists";
                  break;
              }
              return this.$t(message);
            },
          },
        });

        this.notify({
          message: this.$t(this.isUpdate ? 'user.edit.success' : 'user.add.success')
        })

        this.$emit('saved');
        
        if(!this.isUpdate) {
          this.form = { ...defaultForm };
          this.$v.form.$reset();
        }

        this.dialog.display = false;

      } catch (error) {
        // empty
      }

      this.loading = false;
    },
    ...mapActions({
      notify: "notification/notify",
      request: "request"
    }),
  },
  computed: {
    ...mapGetters({ rolesOptions: "user/roles" }),
    isUpdate() {
      return this.user?.id ? true : false;
    },
    roleErrors() {
      const errors = [];

      if (!this.$v.form.role.$dirty) return errors;

      !this.$v.form.role.required &&
        errors.push(this.$t("user.fields.role.required"));
      !this.$v.form.role.matchRole &&
        errors.push(this.$t("user.fields.role.invalid"));

      return errors;
    },
    emailErrors() {
      const errors = [];

      if (!this.$v.form.email.$dirty) return errors;

      !this.$v.form.email.required &&
        errors.push(this.$t("security.fields.email.required"));
      !this.$v.form.email.email &&
        errors.push(this.$t("security.fields.email.invalid"));
      !this.$v.form.email.maxLength &&
        errors.push(this.$t("security.fields.email.invalid"));
      !this.$v.form.email.isUnique &&
        errors.push(this.$t("security.fields.email.exists"));
      return errors;
    },
    phoneErrors() {
      const errors = [];

      if (!this.$v.form.phone.$dirty) return errors;

      !this.$v.form.phone.required &&
        errors.push(this.$t("security.fields.phone.required"));
      !this.$v.form.phone.matchPhone &&
        errors.push(this.$t("security.fields.phone.invalid"));

      return errors;
    },
    firstNameErrors() {
      const errors = [];

      if (!this.$v.form.firstName.$dirty) return errors;

      !this.$v.form.firstName.required &&
        errors.push(this.$t("security.fields.first_name.required"));
      !this.$v.form.firstName.maxLength &&
        errors.push(
          this.$t("security.fields.first_name.max_length", {
            max: this.$v.form.firstName.$params.maxLength.max,
          })
        );

      return errors;
    },
    lastNameErrors() {
      const errors = [];

      if (!this.$v.form.lastName.$dirty) return errors;

      !this.$v.form.lastName.required &&
        errors.push(this.$t("security.fields.last_name.required"));
      !this.$v.form.lastName.maxLength &&
        errors.push(
          this.$t("security.fields.last_name.max_length", {
            max: this.$v.form.lastName.$params.maxLength.max,
          })
        );

      return errors;
    },
    passwordErrors() {
      const errors = [];

      if (!this.$v.form.password.$dirty) return errors;

      Object.prototype.hasOwnProperty.call(this.$v.form.password, "required") &&
        !this.$v.form.password.required &&
        errors.push(this.$t("security.fields.password.required"));

      !this.$v.form.password.minLength &&
        errors.push(
          this.$t("security.fields.password.min_length", {
            min: this.$v.form.password.$params.minLength.min,
          })
        );

      !this.$v.form.password.maxLength &&
        errors.push(
          this.$t("security.fields.password.max_length", {
            max: this.$v.form.password.$params.maxLength.max,
          })
        );

      !this.$v.form.password.matchPassword &&
        errors.push(this.$t("security.fields.password.invalid"));

      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];

      if (!this.$v.form.confirmPassword.$dirty) return errors;
      !this.$v.form.confirmPassword.sameAsPassword &&
        errors.push(this.$t("security.fields.confirm_password.same_as"));

      return errors;
    },
  },
  watch: {
    "dialog.display"(val) {
      if(!val) return;
        
      this.initForm();
    },
    "form.phone"(phone) {
      this.phoneIsValid = false;
      
      if (!phone) {
        this.countryCode = null;
        return;
      }

      if (phone.startsWith("00")) phone = phone.replace(/^(0){2}/, "");
      if (!phone.startsWith("+")) phone = `+${phone}`;
      const number = this.$awesomenumber(phone);
      this.countryCode = number.getRegionCode();
      if (number.isValid()) {
        this.phoneIsValid = true;
      }
    },
  },
};
</script>
