<template>
  <v-dialog v-model="dialog.display" max-width="500">
    <v-card>
      <v-card-title>
        <div class="text-subtitle-1">
          <span class="secondary-font font-weight-bold">
            {{ $t("user.filter.title") }}
          </span>
        </div>
        <v-btn class="ml-auto" icon @click="dialog.display = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-7 pt-7">
        <v-select
          :items="$objectPath.get(regions, $i18n.locale)"
          v-model="filters.region.value"
          dense
          class="mb-1 font-weight-bold"
          :label="$t('user.fields.region.title')"
          append-icon="mdi-map-marker-account-outline"
        ></v-select>
        <v-text-field
          class="font-weight-bold"
          :placeholder="$t('user.fields.email.title')"
          v-model="filters.email.value"
          :label="$t('user.fields.email.title2')"
          clearable
          append-icon="mdi-at"
          autocomplete="off"
        ></v-text-field>
        <v-text-field
          class="font-weight-bold"
          :placeholder="$t('user.fields.username.title')"
          v-model="filters.username.value"
          :label="$t('user.fields.username.title2')"
          clearable
          append-icon="mdi-identifier"
          autocomplete="off"
        ></v-text-field>
        <v-select
          class="font-weight-bold"
          :placeholder="$t('user.fields.role.title')"
          v-model="filters.role.value"
          :label="$t('user.fields.role.title2')"
          clearable
          append-icon="mdi-account-group-outline"
          :items="roles"
          :item-text="(item) => $t(item.text)"
        ></v-select>
        <v-select
          class="font-weight-bold"
          :placeholder="$t('user.fields.status.title')"
          v-model="filters.status.value"
          :label="$t('user.fields.status.title2')"
          clearable
          append-icon="mdi-account-group-outline"
          :items="statuses"
          :item-text="(item) => $t(item.text)"
        ></v-select>
        <v-select
          class="font-weight-bold"
          :placeholder="$t('user.fields.email_confirm.title')"
          v-model="filters.active.value"
          :label="$t('user.fields.email_confirm.title')"
          clearable
          append-icon="mdi-account-group-outline"
          :items="actives"
          :item-text="(item) => $t(item.text)"
        ></v-select>
      </v-card-text>
      <v-card-actions class="mt-0 px-5">
        <v-spacer></v-spacer>
        <v-btn text @click="reset()">{{ $t("btn.reset") }}</v-btn>
        <v-btn
          text
          color="primary"
          class="font-weight-bold"
          @click="filter()"
          >{{ $t("btn.filter") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    dialog: {
      type: Object,
    },
  },
  data: () => ({
    filters: {
      email: {
        operator: "$eq",
        value: null,
      },
      status: {
        operator: "$eq",
        value: null,
      },
      role: {
        operator: "$eq",
        value: null,
      },
      active: {
        operator: "$eq",
        value: null,
      },
      username: {
        operator: "$eq",
        value: null,
      },
      region: {
        field: "extras.value",
        value: null,
      },
      region_item: {
        field: "extras.item",
        value: null,
      },
    },
    roles: [
      { value: "ROLE_CLIENT", text: "user.role.ROLE_CLIENT" },
      { value: "ROLE_COMPTA", text: "user.role.ROLE_COMPTA" },
      { value: "ROLE_COMMERCIAL", text: "user.role.ROLE_COMMERCIAL" },
      { value: "ROLE_ADMIN", text: "user.role.ROLE_ADMIN" },
    ],
    statuses: [
      { value: true, text: "user.fields.status.enabled" },
      { value: false, text: "user.fields.status.disabled" },
    ],
    actives: [
      { value: true, text: "btn.yes" },
      { value: false, text: "btn.no" },
    ],
  }),
  methods: {
    filter() {
      this.$emit("filter", this.filters);
    },
    reset() {
      this.$emit("filter", {});
      this.trackingStatus = null;
      for (const prop in this.filters) {
        this.filters[prop].value = null;
      }
    },
  },
  watch: {
    "filters.region.value"(val) {
      this.filters.region_item.value = val ? "cameroon_region" : null;
    },
  },
  computed: {
    ...mapGetters({ regions: "regions" }),
  },
};
</script>
