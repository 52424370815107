var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-h6 font-weight-medium mb-4"},[_vm._v(_vm._s(_vm.$t("user.title")))]),_c('v-card',{staticClass:"rounded-md mb-4"},[_c('div',{staticClass:"d-flex align-center justify-space-between px-4 py-3 flex-wrap"},[_c('div',[_c('v-btn',{staticClass:"mr-2 mb-2",attrs:{"depressed":"","loading":_vm.loading.refresh},on:{"click":function($event){return _vm.refreshItems()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-refresh")]),_vm._v(" "+_vm._s(_vm.$t("btn.refresh"))+" ")],1),_c('v-btn',{staticClass:"mb-2 mr-2",attrs:{"depressed":"","loading":_vm.loading.filter},on:{"click":function($event){return _vm.filterItems()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter-variant")]),_vm._v(" "+_vm._s(_vm.$t("btn.filter"))+" ")],1),_c('v-btn',{staticClass:"mb-2 mr-2",attrs:{"loading":_vm.loading.export,"disabled":_vm.loading.list,"depressed":""},on:{"click":function($event){return _vm.exportUsers()}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-file-excel-box-outline")]),_vm._v(" "+_vm._s(_vm.$t("btn.export"))+" ")],1)],1),(_vm.$auth.check(['ROLE_ADMIN']))?_c('div',[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.addUser()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-plus-outline")]),_vm._v(" "+_vm._s(_vm.$t("btn.add"))+" ")],1)],1):_vm._e()]),_c('v-data-table',{attrs:{"loading":_vm.loading.list,"headers":_vm.headers,"items":_vm.items,"options":_vm.pagination,"footer-props":_vm.footerProps,"items-per-page":_vm.itemPerPage,"sort-by":"id","sort-desc":"","server-items-length":_vm.meta.total},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([(!_vm._.isEmpty(_vm.items))?{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',[_vm._l((items),function(item,index){return [_c('v-hover',{key:item.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('tr',[_c('td',[_c('span',[_vm._v(_vm._s(_vm.$moment(item.created).format("YYYY-MM-DD HH:mm:ss")))])]),_c('td',[(item.status)?_c('v-chip',{staticClass:"table-chips",attrs:{"color":"success","dark":""}},[_vm._v(_vm._s(_vm.$t("user.fields.status.enabled")))]):_c('v-chip',{staticClass:"table-chips",attrs:{"color":"danger","dark":""}},[_vm._v(_vm._s(_vm.$t("user.fields.status.disabled")))])],1),_c('td',[_c('v-chip',{staticClass:"table-chips",attrs:{"color":"fourth"}},[_vm._v(_vm._s(_vm.$t(("user.role." + (item.role)))))])],1),_c('td',[_vm._v(_vm._s(item.username))]),_c('td',[_vm._v(_vm._s(item.email))]),_c('td',{staticClass:"text-right",staticStyle:{"min-width":"150px"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover || _vm.$vuetify.breakpoint.mdAndDown),expression:"hover || $vuetify.breakpoint.mdAndDown"}],staticClass:"mr-2",attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.displayUser(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-eye-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("btn.display.description")))])]),(_vm.$auth.check(['ROLE_ADMIN']))?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover || _vm.$vuetify.breakpoint.mdAndDown),expression:"hover || $vuetify.breakpoint.mdAndDown"}],staticClass:"mr-2",attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.editUser(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("btn.edit")))])])]:_vm._e(),(
                      item.role !== 'ROLE_ADMIN' &&
                        _vm.$auth.check(['ROLE_ADMIN'])
                    )?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover || _vm.$vuetify.breakpoint.mdAndDown),expression:"hover || $vuetify.breakpoint.mdAndDown"}],staticClass:"mr-2",attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.changeStatus(item, index)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(item.status ? "mdi-checkbox-blank-circle-outline" : "mdi-checkbox-marked-circle-outline"))])],1)]}}],null,true)},[(item.status)?_c('span',[_vm._v(_vm._s(_vm.$t("user.btn.status.disable")))]):_c('span',[_vm._v(_vm._s(_vm.$t("user.btn.status.enable")))])])]:_vm._e(),(
                      ((_vm.$auth.check(['ROLE_COMMERCIAL']) &&
                        item.role === 'ROLE_CLIENT') ||
                        _vm.$auth.check(['ROLE_ADMIN'])) &&
                        item.status
                    )?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover || _vm.$vuetify.breakpoint.mdAndDown),expression:"hover || $vuetify.breakpoint.mdAndDown"}],staticClass:"mr-2",attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.impersonateUser(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-account-arrow-left-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("user.btn.impersonate")))])])]:_vm._e()],2)])]}}],null,true)})]})],2)]}}:null],null,true)})],1),_c('user-view',{attrs:{"dialog":_vm.dialog.view,"user":_vm.user}}),_c('user-filter',{attrs:{"dialog":_vm.dialog.filter},on:{"filter":_vm.applyFilter}}),_c('confirm',{attrs:{"dialog":_vm.dialog.status},on:{"confirm":_vm.confirmChangeStatus}}),_c('user-form',{attrs:{"user":_vm.user,"dialog":_vm.dialog.form},on:{"saved":_vm.refreshItems}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }